.handbook {
  display: flex;
  max-height: calc(100vh - 80px);
  max-width: 100%;
  width: 100vw;

  aside {
    border: 1px solid $light-grey;
    background: $background-grey;
    display: flex;
    flex-direction: column;
    width: 440px;
    max-width: 440px;

    header {
      .title {
        border-bottom: 1px solid $light-grey;
        background: $blue;
        font-size: 16px;
        color: white;
        font-weight: 600;
        padding: 17px 20px;

        button {
          display: none;
        }
      }

      nav {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);

        button, a {
          padding: 18px 0 12px;
          border-bottom: 6px solid white;
          border-radius: 0px;
          color: $text;
          font-weight: 300;
          font-size: 12px;
          text-decoration: none;
          text-align: center;
          background-color: white;

          svg {
            path, circle {
              fill: #AAAAAA;
            }
          }

          &.active {
            border-bottom: 6px solid $blue;
            font-weight: 700;

            svg {
              path, circle {
                fill: $blue;
              }
            }
          }
        }
      }
    }

    ul {
      list-style: none;
      margin: 0px;
      padding: 0px;
    }

    .nav-container {
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      & > ul {
        margin: 20px;

        button, li > div, a {
          display: block;
          background: white;
          font-weight: 700;
          padding: 17px 12px;
          color: $dark-grey;
          text-align: left;
          position: relative;
          box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
          overflow: hidden;
          border-radius: 4px;
          text-decoration: none;
          font-size: 14px;

          .progress {
            height: 3px;
            background: white;
            position: absolute;
            bottom: 0px;
            left: 0px;
          }
        }



        & > li {
          display: flex;
          flex-direction: column;

          & > ul {
            display: none;
          }

          &.open > ul {
            display: block;
          }

          & > button, & > a {
            margin-bottom: 10px;
            display: flex;

            span {
              flex-grow: 1;
            }

            &::after {
              content: '';
              width: 24px;
              height: 16px;
              display: block;
              background: url(../images/design/arrow-more.svg);
              background-position: 50%;
            }

            &.active::after {
              background: url(../images/design/arrow-less.svg);
              background-position: 50%;
            }
          }

          &.collapsed {
            & > button, & > a {
              &.active::after {
                background: url(../images/design/arrow-more-white.svg);
                background-position: 50%;
              }
            }
          }

          ul {
            li {
              margin-bottom: 10px;

              a {
                font-weight: 300;

                span {
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }

      & > ul.audio {
        li {
          margin-bottom: 10px;

          & > div {
            border-radius: 4px;

            .progress {
              background: $blue;
            }

            &.active {
              background: $blue;
              color: white;

              .progress {
                background: white;
              }
            }

            span {
              display: block;
              font-size: 12px;

              &:last-of-type {
                font-weight: 300;
              }
            }
          }
        }
      }

      ul > li.hidden, ul > li > ul > li.hidden { display: none; }
    }


    ul {
      li.un-blue {
        button.active, a.active {
          background: $un-blue;
          color: white;
        }

        button:not(.active), a:not(.active) {
          .progress {
            background: $un-blue;
          }
        }
      }

      li.un-green {
        button.active, a.active {
          background: $un-green;
          color: white;
        }

        button:not(.active), a:not(.active) {
          .progress {
            background: $un-green;
          }
        }
      }

      li.un-purple {
        button.active, a.active {
          background: $un-purple;
          color: white;
        }

        button:not(.active), a:not(.active) {
          .progress {
            background: $un-purple;
          }
        }
      }

      li.un-orange {
        button.active, a.active {
          background: $un-orange;
          color: white;
        }

        button:not(.active), a:not(.active) {
          .progress {
            background: $un-orange;
          }
        }
      }

      li.un-navy {
        button.active, a.active {
          background: $un-navy;
          color: white;
        }

        button:not(.active), a:not(.active) {
          .progress {
            background: $un-navy;
          }
        }
      }

      li.un-red {
        button.active, a.active {
          background: $un-red;
          color: white;
        }

        button:not(.active), a:not(.active) {
          .progress {
            background: $un-red;
          }
        }
      }
    }
  }

  .content {
    max-width: calc(100% - 440px);
    position: relative;
    height: 100%;
    flex-grow: 1;

    ol:last-of-type { margin-bottom: 0px; }

    header {
      padding: 26px 40px 33px;
      position: relative;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);

      & > div.search-back {
        border-bottom: 1px solid #ccc;
        padding-bottom: 16px;
        margin-bottom: 20px;

        a {
          text-decoration: none;
          color: #3c3c3c;
          font-size: 14px;
          font-weight: 300;
          line-height: 25px;
          margin-left: 30px;
          flex: 1;
        }

        span {
          color: #ccc;
          font-size: 26px;
          position: absolute;
          left: 40px;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      & > div:first-of-type,
      & > div:nth-of-type(2) {
        display: flex;
        align-items: center;

        & > div:first-of-type {
          flex-grow: 1;
        }

        & > div.desktop-options {
          button {
            color: $text;
            white-space: nowrap;
            display: block;
            margin-bottom: 5px;
            padding-left: 0;
            padding-right: 0;
          }
        }

        & > div.mobile-options {
          position: relative;
          display: none;

          button {
            padding: 5px;
          }

          div.submenu {
            position: absolute;
            top: calc(100% + 5px);
            right: 0px;
            padding: 21px 15px;
            background: white;
            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
            z-index: 10;
            border-radius: 2px;

            button {
              color: $text;
              white-space: nowrap;
              display: flex;
              align-items: center;
              margin-bottom: 5px;

              &:last-of-type { margin-bottom: 0px; }

              img {
                margin-right: 10px;
              }
            }
          }
        }
      }

      h1 {
        font-size: 20px;
        margin-bottom: 8px;
        font-weight: 700;
        margin-top: 0px;
      }

      span {
        font-weight: 300;
        font-size: 14px;
      }

      .progress-container {
        background: $background-grey;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;

        .progress {
          height: 10px;
        }
      }
    }

    & > div {
      max-height: 100%;
    }

    .scroller {
      padding: 0 40px;
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh  - 202px);

      &.search-back {
        height: calc(100vh  - 264px);
      }

      .continue {
        display: none;
      }

      table{
        display: block;
        overflow-x: auto;
      }

      .d-inline-table {
        display: inline-table;
      }


      @mixin section-colored($color, $color-25, $color-50, $color-75) {
        a { color: $color; }
        table {
          &.striped {
            thead th, thead td {
              background: $color;
              color: white;
            }

            tbody tr:nth-child(even) td {
              background: $color-50
            }

            tbody tr td {
              padding: 5px 10px;
            }

            border-bottom: 5px solid $color;

            &.bold {
              tbody tr td {
                font-weight: 700;
              }
            }

            &.bordered {
              border: 2px solid $color;
              thead {
                td, th {
                  padding: 5px 10px;
                  white-space: nowrap;
                }
              }
              tbody {
                td {
                  border-right: 2px dotted $color;
                }
              }
            }

            &.no-header {
              border-bottom: 0px;

              tr:nth-child(odd) td {
                background: $color-25;
              }

              tr:nth-child(even) td {
                background: transparent;
              }

              tr {
                td:first-of-type {
                  font-size: 24px;
                  color: $color;
                  font-weight: 700;
                }

                td:nth-child(2) {
                  font-weight: 700;
                }
              }
            }
          }

          &.examples {
            border: 2px solid $color;
            width: 100%;

            img {
              max-width: 100%;
              height: auto;
            }

            thead th, thead td {
              background: $color;
              color: white;
              font-weight: 700;
              text-align: left;
              padding: 5px 20px;
            }

            thead {
              tr:nth-child(2) {
                td {
                  background: $color-50;
                  color: $text;
                  font-weight: 300;
                  border-bottom: 2px solid black;
                }
              }
            }

            tbody {
              td {
                padding: 20px;
                vertical-align: top;
                font-size: 14px;

                &:first-of-type {
                  border-right: 2px dotted $color;
                }

                &:last-of-type {
                  border-right: none;
                }

                & > p:first-of-type {
                  margin-top: 0px;
                }

                & > p:last-of-type {
                  margin-bottom: 0px;
                }

                & > ul:first-of-type {
                  margin-top: 0px;
                }

                & > ul:last-of-type {
                  margin-bottom: 0px;
                }

                &.no-padding {
                  padding: 0px;
                }

                p.padded, div.padded {
                  padding: 20px 20px 0;
                }

                p.padded-bottom {
                  padding: 0 20px 0;
                }

                h4 {
                  color: $color;
                  margin: 0 0 5px;
                }

                h5 {
                  margin: 5px 0;
                  font-size: 14px;
                }
              }
            }

            &.border-bottom {
              tbody {
                tr {
                  border-bottom: 1px solid $color;
                }
              }
            }

            &.underborder {
              tbody {
                tr {
                  border-bottom: 2px dotted $color;
                  td {
                    padding: 5px;

                    & > ul {
                      margin: 0px;
                    }

                    &:first-of-type {
                      border-right: none;
                    }
                  }
                }
              }
            }

            &.fade {
              tbody {
                tr {
                  &:first-of-type td {
                    background: $color-25;
                  }

                  &:last-of-type td {
                    background: $color-75;
                  }

                  td {
                    padding: 10px;
                    background: $color-50;

                    p:first-of-type { margin-top: 0px; }
                    p:last-of-type { margin-bottom: 0px; }
                  }
                }
              }
            }

            &.fade-up {
              thead { td { border-right: 1px solid $color; } }
              tbody {
                tr {
                  td {
                    border-right: 1px solid $color;
                    border-bottom: 2px dotted $color;
                  }

                  &:last-of-type {
                    td {
                      border-bottom: none;
                    }
                  }

                  td:nth-child(2) {
                    background: $color-25;
                  }

                  td:nth-child(3) {
                    background: $color-50;
                  }

                  td:nth-child(4) {
                    background: $color-75;
                  }
                }
              }
            }

            &.horizontal {
              thead {
                td {
                  background: $color;
                  border-right: 2px solid $color;
                }
              }
              tbody {
                tr {
                  td {
                    border-right: 2px solid $color;
                    padding: 10px;

                    &:nth-child(even) {
                      background: $color-25;
                    }
                  }

                  &.subhead {
                    td { background: $color-25; }
                    border-bottom: 2px solid $color;
                  }
                }
              }
            }

            &.two-column {
              border: none;
              tbody {
                tr {
                  border-bottom: 2px solid black;

                  td {
                    &:first-of-type {
                      background: $color-25;
                      border-right: 2px solid black;
                    }
                  }
                }
              }
            }
          }

          &.horizontal {
            thead {
              tr {
                td, th {
                  background: $color-25;
                  font-weight: 700;
                }
              }
            }

            tbody {
              tr {
                td {
                  border-bottom: 1px solid black;

                  &:first-of-type {
                    width: 40%;
                  }
                }

                &:last-of-type {
                  td {
                    border-bottom: none;
                  }
                }
              }
            }
          }

          &.boxed-table {
            tr {
              border-bottom: 2px dotted $color;

              &:last-of-type {
                border-bottom: none;
              }

              td {
                vertical-align: top;
                font-size: 14px;
                padding: 10px 0;

                &:first-of-type {
                  width: 30%;
                  padding-right: 20px;
                }

                p:first-of-type {
                  margin-top: 0px;
                }

                ul {
                  margin: 0px;
                }
              }
            }
          }

          &.columns {
            border: 2px solid $color;
            border-collapse: separate !important;
            border-spacing: 5px;

            tr:first-of-type {
              td {
                max-height: 125px;
                img {
                  width: 100%;
                  object-fit: cover;
                  display: block;
                  max-height: 125px;
                }
              }
            }

            tr:last-of-type {
              td {
                text-align: center;
                background: $color-50;
                padding: 10px;
                vertical-align: top;

                p.title {
                  font-weight: bold;
                }

                p.subtitle { font-weight: 700; }
              }

              td:first-of-type {
                background: $color-25;
                padding: 5px;
              }

              td:last-of-type {
                background: $color-75;
                padding: 5px;
              }
            }

            &.align-left {
              tbody {
                td {
                  text-align: left;

                  p {
                    text-align: center;
                  }
                }
              }
            }
          }

          &.checklist {
            tr {
              td:first-of-type {
                text-align: center;
              }
            }

            thead {
              tr {
                border-bottom: 2px dotted $color;
                td, th {
                  color: $color;
                  padding: 10px 0;

                  &:first-of-type::before {
                    content: '\2713';
                    font-size: 30px;
                  }
                }
              }
            }

            tbody {
              tr {
                &:last-of-type td { border-bottom: none; }
                td {
                  padding: 10px 0;
                  border-bottom: 1px dotted $color;
                }

                td.checked{
                  vertical-align: top;
                  padding: 10px 20px;
                  border-bottom: none;

                  &::before {
                    content: '\2611';
                    font-size: 30px;
                  }
                }
              }
            }
          }

          &.title-left {
            tr {
              padding-bottom: 20px;
              td:first-of-type {
                color: $color;
                font-weight: 700;
                border-right: 1px solid $color;
                padding: 0 10px 0 0;
              }

              td:last-of-type {
                padding: 0 0 0 10px;
              }

              td.spacer {
                height: 20px;
              }
            }
          }

          &.grid {
            thead {
              tr {
                th, td {
                  background: $color-25;
                  color: $color;
                  padding: 3px 10px;
                  border-right: 1px solid $color;

                  &:last-of-type {
                    border-right: none;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  border-bottom: 2px dotted $color;
                  border-right: 1px solid $color !important;
                  padding: 10px;

                  & > ol:first-of-type {
                    margin-top: 0px;
                  }

                  & > ol:last-of-type {
                    margin-bottom: 0px;
                  }

                  &:last-of-type {
                    border-right: none !important;
                  }
                }

                &:last-of-type {
                  td {
                    border-bottom: none;
                  }
                }
              }
            }
          }

          &.odd-even {
            thead {
              tr {
                td, th {
                  padding: 5px 10px;
                  text-align: center;
                  &:nth-child(even) { background: $color-25; }
                  &:nth-child(odd) { background: $color-50; }
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 10px;
                  text-align: center;
                  &:nth-child(even) {
                    background: $color-25;
                    border-bottom: 10px solid $color-75;
                  }
                  &:nth-child(odd) { border-bottom: 10px solid $color-50; }
                }
              }
            }
          }

          &.impact {
            tbody {
              tr {
                border-bottom: 2px solid black;

                &.header {
                  td {
                    background: $color;
                    font-weight: 700;
                    text-align: center;
                    color: white;
                    padding: 5px 10px;
                  }
                }

                td {
                  padding: 10px;
                  &:first-of-type {
                    border-right: 2px solid black;
                    text-align: center;
                    font-weight: 700;
                  }

                  &.red { background: red; }
                  &.orange { background: orange; }
                  &.yellow { background: yellow; }
                }
              }
            }
          }

          &.vertical {
            thead {
              th, td {
                background: $color-25;
                color: $text;
                border-right: none !important;
              }
            }

            tbody {
              tr {
                td {
                  border-right: 1px solid black;

                  &:last-of-type {
                    border-right: none;
                  }
                }
              }
            }

            &.with-border {
              border: 2px solid black;

              thead {
                th, td {
                  border-right: 1px solid black !important;
                }
              }
            }

            &.centered {
              thead {
                td, th {
                  padding: 10px;
                }
              }
            }
          }
        }

        ul, ol {
          padding: 0 0 0 20px;

          &.color {
            & > li {
              color: $color;
              ul, ol {
                color: $text;
              }
            }

            &.bold {
              & > li {
                font-weight: 700;
                ul, ol {
                  font-weight: 300;
                }
              }
            }
          }

          &.headers > li { font-weight: 700; }

          & > p, & > div, & > ul {
            margin-left: -15px;
          }
        }

        ul.circle {
          list-style: none;

          li::before {
            content: "\2022";
            color: $color;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }

        ul.dash {
          list-style: none;

          li::before {
            content: "\2043";
            color: $color;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }

        ul.square {
          list-style: none;

          li::before {
            content: "\25AA";
            color: $color;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }

        ul.triangle {
          list-style: none;

          li::before {
            content: "\2023";
            color: $color;
            font-weight: bold;
            display: inline-block;
            font-size: 1.2em;
            width: 1em;
            margin-left: -1em;
          }
        }

        ul.checklist {
          list-style: none;

          li::before {
            content: "\2611";
            color: $color;
            font-weight: bold;
            display: inline-block;
            font-size: 1.2em;
            width: 1em;
            margin-left: -1em;
          }
        }

        ul.reminders {
          list-style: none;

          li::before {
            content: "\272A";
            color: $color;
            font-weight: bold;
            display: inline-block;
            font-size: 1.2em;
            width: 1em;
            margin-left: -1em;
          }
        }

        ul.striped {
          padding: 0px !important;

          &.no-bullets {
            list-style: none;
          }

          li:nth-child(odd) {
            background: $color-25;
          }

          li {
            padding-left: 20px;
          }

          &.even {
            li:nth-child(even) {
              background: $color-25;
            }

            li {
              background: transparent;
            }
          }
        }

        .flow {
          .node { border: 2px solid $color; };
          .line {  border-bottom: 2px dotted $color; }
        }

        .grid-three {
          padding-bottom: 50px;
          border-bottom: 2px dotted $color;
          margin-bottom: 20px;

          h4 {
            background: $color;
          }

        }

        .grid-four {
          margin-top: 10px;

          .box {
            border: 2px solid $color;

            header {
              padding: 3px 0;
              box-shadow: none;
              text-align: center;
              font-weight: 700;
              background: $color-25;
            }

            p {
              margin: 10px;
            }
          }
        }

        .alt-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;

          & > div {
            padding: 20px;
            text-align: center;
            border-bottom: 1px dotted $color;
          }

          & > div:nth-child(odd) {
            background: $color-50;
          }

          & > div:nth-child(even) {
            background: $color-25;
          }

          & > div:nth-child(9), & > div:nth-child(10) {
            border-bottom: none;
          }
        }

        .to-do {
          border: 2px solid $color;

          header {
            padding: 0 0 10px 0;
            box-shadow: none;
            font-weight: bold;
            display: flex;
            align-items: center;
          }

          .do {
            padding: 10px;

            header {
              color: $color;

              &::before {
                content: '\2713';
                font-size: 20px;
                margin-right: 10px;
              }
            }

            p {
              border-bottom: 2px dotted $color;
              margin: 0 0 5px;
            }
          }

          .dont {
            padding: 10px;
            background: $color-25;

            header {
              color: $text;

              &::before {
                content: '\2716';
                font-size: 20px;
                margin-right: 10px;
              }
            }

            p {
              border-bottom: 2px dotted $text;
              margin: 0 0 5px;
            }
          }
        }

        div.odd-even {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-row-gap: 10px;

          & > div {
            padding: 10px;

            &:nth-child(even) {
              background: $color-25;
            }

            &:nth-child(odd) {
              border-top: 10px solid $color-50;
              border-bottom: 10px solid $color-50;
            }
          }
        }

        .background {
          background: $color-25; padding: 10px;

          p:first-of-type {
            margin-top: 0px;
          }

          p:last-of-type {
            margin-bottom: 0px;
          }
        }
        .border { border: 1px solid $color; }

        .case-study,
        .toolkit {
          background: $color-25;
          overflow: hidden;

          h3 {
            background: $color;
            color: white;
            padding: 3px 20px;
            margin: 0px;
          }

          h4 {
            padding: 0 20px;
            margin: 10px 0;
          }

          .content {
            max-width: none;
            padding: 0 20px;
            height: auto;
          }

          .relative {
            float: right;
            width: 60%;
            padding: 0 0 20px 20px;
            margin-right: 20px;

            img {
              width: 100%;
            }

            .description {
              bottom: 30px !important;
              left: 30px !important;
            }

            .ref {
              bottom: 108px !important;
            }

            &.top-right {
              .description {
                top: 10px;
                right: 10px;
                bottom: auto !important;
                left: auto !important;
              }
            }
          }

          .half {
            .relative {
              width: calc(100% - 10px);
              padding: 0px;
              .description {
                bottom: 10px !important;
                left: 10px !important;
              }
              .ref {
                bottom: 88px !important;
              }
            }
          }
        }

        .voices {
          position: relative;

          .avatar {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100px;
            width: 100px;
            border: 1px solid grey;
            z-index: 100;

            img {
              height: 100px;
              width: 100px;
            }
          }

          h3 {
            margin-left: 120px;
            background: $color;
            padding: 3px 10px;
            color: white;
            margin-bottom: 0px;
          }

          .content {
            background: $color-25;
            max-width: none;
            padding-top: 25px;
            border-top: 10px solid $color-50;
            margin-top: -5px;

            h4 {
              margin: 0 0 0 130px;
              font-size: 16px;
            }

            h5 {
              font-size: 14px;
              margin: 0 0 10px 130px;
              border-bottom: 1px dotted $color;
              padding-bottom: 10px;
            }

            cite {
              font-size: 18px;
              font-weight: 700;
              font-style: normal;
              margin: 10px 20px;
              display: inline-block;
              line-height: 35px;
            }

            .half {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 20px;
              margin: 0 20px;

              cite {
                margin: 10px 0;
              }
            }
          }
        }

        .horizontal-flow {
          border: 2px solid $color;
          padding: 10px;

          div {
            background: $color-50;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
            margin-bottom: 10px;

            header, footer {
              padding: 5px 10px;
              background: white;
              color: $color;
              box-shadow: none;
              font-weight: 700;
            }

            &.border-dashed {
              padding: 0px;
              border-bottom: 2px dashed $color;
              background: transparent;
            }

            &:last-of-type {
              margin-bottom: 0px;
            }
          }

          div:first-of-type, div:last-of-type {
            background: $color-25;
          }
        }

        .template {
          & > ol > li::marker {
            color: $color;
            font-weight: bold;
          }
        }

        ol.marked-color {
          & > li::marker {
            color: $color;
            font-weight: bold;
          }
        }

        .half.side-by-side, .vs {
          padding: 10px;
          border: 2px solid $color;
          background: $color-25;

          & > div {
            background: white;
            padding: 10px;
            box-shadow: 4px 4px 10px -2px rgba(0,0,0,0.44);

            h4 { margin-top: 0px; }
            ul { margin-bottom: 0px; }
          }
        }

        .vs {
          & > span {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            color: $color;
          }
        }

        .operations {
          & > div {
            padding: 20px;
            margin-bottom: 10px;
            h4 {
              margin-top: 0px
            }
          }

          & > div:first-of-type {
            background: $color-75;
          }

          & > div:nth-child(2) {
            background: $color-50;
          }

          & > div:last-of-type {
            background: $color-25;
          }
        }

        .color-25 {
          background: $color-25;
        }

        .crisis {
          display: grid;
          grid-template-columns: 1fr 1fr 2fr;
          grid-column-gap: 10px;

          & > div {
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 70px;
          }

          & > div:first-of-type {
            background: $color;
            color: white;
            box-shadow: 4px 4px 10px -2px rgba(0,0,0,0.44);
            margin-top: 12px;
          }

          & > div:nth-child(2) {
            background: $color-75;
            color: white;
            box-shadow: 4px 4px 10px -2px rgba(0,0,0,0.44);
            margin-top: 12px;
          }

          & > div:last-of-type {
            border: 2px solid $color;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: auto;

            & > div {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-column-gap: 10px;
              width: 100%;
              margin-bottom: 10px;

              & > div {
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 4px 4px 10px -2px rgba(0,0,0,0.44);
                color: $color;
              }

              & > div:first-of-type {
                background: $color-50;
              }

              & > div:last-of-type {
                background: $color-25;
                border-radius: 50%;
              }
            }
            span { color: $color; }
          }
        }
      }

      &.un-blue {
        @include section-colored($un-blue, $un-blue-25, $un-blue-50, $un-blue-75);
      }

      &.un-green {
        @include section-colored($un-green, $un-green-25, $un-green-50, $un-green-75);
      }

      &.un-orange {
        @include section-colored($un-orange, $un-orange-25, $un-orange-50, $un-orange-75);
      }

      &.un-purple {
        @include section-colored($un-purple, $un-purple-25, $un-purple-50, $un-purple-75);
      }

      &.un-navy {
        @include section-colored($un-navy, $un-navy-25, $un-navy-50, $un-navy-75);
      }

      &.un-red {
        @include section-colored($un-red, $un-red-25, $un-red-50, $un-red-75);
      }

      .main-content {
        font-size: 14px;
        font-weight: 300;
        margin-top: 20px;
        margin-bottom: 20px;
        min-width: 250px;
        img {
          max-width: 100%;
          height: auto;
        }

        .half {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 20px;

          & > div:last-of-type {
            text-align: right;
          }

          &.acronyms {
            & > div > div {
              display: flex;


              span:first-of-type {
                white-space: nowrap;
                margin-right: 5px;
              }
            }
            & > div:last-of-type {
              text-align: left;
            }
          }
        }

        .logo {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-weight: 700;

          img { margin-bottom: 10px; }
        }

        p.no-margin { margin: 0px; }

        &.full {
          margin-left: 0px;
        }

        .underline { text-decoration: underline; }
        h5 { font-size: 14px; }
        .indent { padding-left: 20px; }

        .text-image {
          display: grid;
          grid-template-columns: 1fr 1fr;

          .bold {
            font-weight: 700;
          }

          div:last-of-type {
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
        }

        .centered {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 50px;
          margin-bottom: 50px;

          p.bordered {
            border-top: 3px solid black;
            border-bottom: 3px solid black;
            width: 50%;
            padding: 20px 0;
            margin-bottom: 100px;

            &.full {
              width: 100%;
              margin-bottom: 20px;
            }
          }

          p.border-bottom {
            width: 50%;
            border-bottom: 3px solid black;
            padding-bottom: 20px;
          }

          .contents-table {
            display: grid;
            grid-template-columns: 1fr 1fr;
            border-bottom: 2px solid black;
            width: 100%;
            padding-bottom: 50px;

            p { text-align: right; }
            ol {
              text-align: left;
              margin-left: 10px;
              font-weight: 700;
            }
          }
        }

        ol {
          h4, h5, p {
            margin-left: -20px;
          }
        }

        table {
          border-collapse: collapse;
          &.vertical {
            thead {
              td, th {
                background: transparent;
                border-bottom: 2px solid black;
              }
            }

            td {
              padding: 5px 10px;
            }
          }

          &.directory {
            border: 2px solid black;
            margin-bottom: 20px;

            td {
              padding: 10px;
              border: 2px solid black;
              text-align: center;
            }

            tr td.subhead {
              background: $un-orange-50;
            }

            thead {
              td, th {
                background: $un-orange-25;
              }
            }

            tbody {
              td {
                &.green { background: $un-green-25; width: 80px; }
                &.purple { background: $un-navy-25; }
                &.yellow { background: yellow; }
              }
            }

            &.second-align-left {
              ul {
                text-align: left;
                margin: 0px;
              }
            }
          }
        }
      }


      .grid-three {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
        grid-column-gap: 50px;
        grid-row-gap: 40px;
        text-align: center;
        margin-top: 20px;

        & > div {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        h4 {
          color: white;
          padding: 4px 15px 3px;
          margin: 0 0 10px;
          z-index: 2;
        }

        p {
          margin: 0px;
          font-weight: 300;
          font-size: 12px;
        }
      }

      .section {
        display: flex;

        &:not([style*="display: none"]) {
          margin-bottom: 40px;
        }

        .track {
          .title {
            min-width: 200px;
            margin-right: 30px;
            position: -webkit-sticky;
            position: sticky;
            top: 60px;

            span {
              margin-top: 60px;
              font-size: 20px;
              font-weight: 700;
              display: block;
              margin-bottom: 10px;
            }

            h3 {
              margin: 0px;
              padding: 0 0 20px;
              font-size: 14px;
              font-weight: 700;
              border-bottom: 1px solid $light-grey;
              text-transform: uppercase;
            }
          }
        }

        .main {
          font-weight: 300;
          font-size: 14px;
          line-height: 25px;
          min-width: 250px;

          & > p:last-of-type {
            margin-bottom: 0px;
          }

          .template {
            border: 1px solid #ccc;
            padding: 40px 20px 20px;
            border-radius: 12px;
            box-shadow: 14px 13px 13px -14px rgba(0,0,0,0.62);
            position: relative;

            &.margin-top { margin-top: 20px; }

            &.padding-left {
              padding: 40px 0 0 20px;

              .border {
                border-right: none;
                border-bottom: none;
              }
            }

            &.padding-right {
              padding: 40px 20px 0 0;

              .border {
                border-left: none;
                border-bottom: none;
              }
            }

            &::before {
              content: '';
              position: absolute;
              top: -17px;
              left: 20px;
              width: calc(100% - 40px);
              height: 34px;
              background: url(../images/design/binder.svg);
            }

            h4 {
              margin: 0 0 20px;
              font-size: 18px;
            }
          }

          .with-borders {
            border: 2px solid black;
            font-size: 10px;

            thead {
              td, th {
                border: 2px solid black;
                padding: 5px;
                h4 { margin: 0px; }
              }
            }

            tbody {
              td {
                border: 1px solid black;
                padding: 5px;
              }
            }
          }

          .risk {
            width: 100%;

            thead {
              tr {
                td, th {
                  border: 2px solid black;
                  text-align: center;

                  &:first-of-type {
                    border: none;
                  }
                }

                &:nth-child(2) td:last-of-type {
                  background: green;
                  color: white;
                  text-transform: uppercase;
                }

                &:last-of-type {
                  td {
                    border: none;
                    border-right: 1px dotted black;

                    &:last-of-type {
                      border-right: 2px solid black;
                    }

                    &:first-of-type {
                      border-top: 2px solid black;
                      border-left: 2px solid black;
                      border-right: 2px solid black;
                      background: green;
                      color: white;
                    }
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 5px;
                  text-align: center;

                  &:nth-child(2) {
                    border-left: 2px solid black;
                  }

                  &:first-of-type {
                    border-bottom: 1px dotted black;
                    border-left: 2px solid black;
                    border-right: 2px solid black;
                  }

                  &:last-of-type {
                    border-right: 2px solid black;
                  }

                  &.yellow, &.orange, &.green, &.red {
                    position: relative;
                    font-weight: bold;
                    &::before {
                      content: '';
                      position: absolute;
                      top: 1px;
                      left: 1px;
                      right: 1px;
                      bottom: 1px;
                      z-index: -1;
                    }
                  }

                  &.yellow {
                    &::before { background: yellow; }
                  }
                  &.orange {
                    &::before { background: orange; }
                  }
                  &.green {
                    &::before { background: green; }
                  }
                  &.red {
                    &::before { background: red; }
                  }
                }

                &:last-of-type {
                  border-bottom: 2px solid black;
                  td:first-of-type {
                    border-bottom: 2px solid black;
                  }
                }
              }
            }
          }

          .templateSecurityOperations{
            table{
              width: 100%;
              table-layout: fixed;
            }
            ol{
              margin-top: 0;
              li::marker{
                font-weight: bold;
                color: $un-navy;
              }
              li{
                li::marker{
                  color: $text;
                }
                li{
                  li::marker{
                    font-weight: 300;
                  }
                }
              }
            }
            tbody{
              tr{
                vertical-align: top;
                td{
                  padding-left: 20px;
                  width: 50%;
                  &:first-of-type{
                    border-right: 1px dotted #3940A1;
                  }
                }
              }
            }
            span{
              font-weight: bold;
            }
          }

          .bold {
            font-weight: 700;
          }

          h5 {
            font-size: 16px;
            margin: 20px 0 10px;
            span { margin-right: 5px; }
          }

          .margin-bottom { margin-bottom: 10px; }
          .margin-top { margin-top: 10px; }

          ol {
            padding: 0 0 0 15px;

            ul {
              padding: 10px 0 0 25px;
            }
          }

          ol.refs {
            padding: 0 0 0 20px;
            font-size: 12px;
            margin-bottom: 20px;

            li {
              padding-left: 10px;
              word-break: break-word;
            }
          }


          p.underline {
            text-decoration: underline;
          }

          ol.references {
            font-size: 0.8em;
          }

          table {
            border-collapse: collapse;
            font-size: 14px;

            thead {
              tr td { font-weight: 700; }
            }

            tbody {
              &.second-aligned-right {
                tr td:nth-child(2) {
                  text-align: right;
                }
              }
            }

            &.striped {
              th {
                padding: 5px 10px;
                font-weight: 700;
              }
            }
          }

          .bold { font-weight: bold; }

          .text-image {
            display: flex;
            flex-direction: row;

            & > div { flex-grow: 1; }
          }

          .equation {
            display: grid;
            grid-template-columns: 1fr 1fr;

            & > div:first-of-type {
              text-align: right;
            }

            & > div > div {
              text-align: center;

              &:first-of-type {
                border-bottom: 1px solid #000;
              }
            }
          }

          .logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            img { margin-bottom: 8px; }
          }

          .half {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
          }

          .green-background {
            background: #EBF4E3;
            padding: 10px;

            &.border {
              border: 1px solid #31B450;
            }
          }

          .flow {
            display: grid;
            grid-template-columns: 1fr 50px 1fr 50px 1fr;

            .node {
              padding: 10px;
              font-weight: 700;
              display: flex;
              align-items: center;
            }

            .line {
              height: 50%;
              width: 50px;
            }
          }

          .start-third {
            display: grid;
            grid-template-columns: 33% 66%;
            grid-column-gap: 10px;

            & > p:first-of-type { margin-top: 0px; }

            & > img {
              max-width: 100%;
              height: auto;
            }
          }

          .clear {
            clear: both;
          }

          .relative {
            position: relative;

            &.float-right {
              float: right;
              max-width: 50%;
            }

            img {
              width: 100%;
              display: block;
            }

            .description {
              background: rgba(0,0,0,0.3);
              color: white;
              padding: 10px;
              position: absolute;
              bottom: 10px;
              left: 10px;
              max-width: 250px;

              p:first-of-type { margin-top: 0px; }
              p:last-of-type { margin-bottom: 0px; }
            }

            p.ref {
              position: absolute;
              transform: rotate(90deg);
              bottom: 88px;
              right: -115px;
              width: 200px;
              text-align: right;
              margin: 0px;
            }

            &.top-right {
              .description {
                top: 10px;
                right: 10px;
                bottom: auto;
                left: auto;
              }
            }
          }

          span.boxed {
            display: inline-block;
            padding: 2px 5px;
            color: white;
            font-weight: 700;

            &.un-blue { background: $un-blue; }
            &.un-green { background: $un-green; }
            &.un-orange { background: $un-orange; }
            &.un-purple { background: $un-purple; }
            &.un-navy { background: $un-navy; }
            &.un-red { background: $un-red; }
          }

          h4.boxed {
            display: block;
            padding: 8px 10px;

            .un-green {
              background: #31B450;
            }
          }

          span.border {
            display: block;
            padding: 3px 10px;
            color: #000;
            border: 1px solid;

            &.un-green {
              border-color: #31B450;
            }
          }

          .grid-four {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 10px;
          }

          p.large {
            font-size: 1.5em;
          }

          p.small {
            font-size: 0.85em;
          }

          div.circle {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin: 0 auto;

            &.red { background: red; }
            &.orange { background: orange; }
            &.green { background: green; }
          }

          div.vs {
            display: grid;
            grid-template-columns: 1fr 40px 1fr;
            grid-column-gap: 10px;
          }

        }
      }

      &.un-blue {
        .section {
          .title {
            span:first-of-type {
              color: $un-blue;
            }
          }
        }
      }

      &.un-green {
        .section {
          .title {
            span:first-of-type {
              color: $un-green;
            }
          }
        }
      }

      &.un-purple {
        .section {
          .title {
            span:first-of-type {
              color: $un-purple;
            }
          }
        }
      }

      &.un-orange {
        .section {
          .title {
            span:first-of-type {
              color: $un-orange;
            }
          }
        }
      }

      &.un-navy {
        .section {
          .title {
            span:first-of-type {
              color: $un-navy;
            }
          }
        }
      }

      &.un-red {
        .section {
          .title {
            span:first-of-type {
              color: $un-red;
            }
          }
        }
      }

      &.text-only {
        .audio-player,
        .voices .avatar,
        .relative {
          display: none;
        }

        .start-third {
          grid-template-columns: 1fr !important;
          img { display: none; }
        }
      }
    }
  }

  .has-flow {
    .scroller {
      .section, .flow-hide {
        display: none;
      }
      .section-footnotes {
        li {
          visibility: hidden;
          height: 0;
        }
      }
    }
  }
}

.audio-player {
  background: white;
  border-radius: 4px;
  border: 1px solid $light-grey;
  padding: 20px;
  margin: 15px 0;

  .row {
    display: flex;
    flex-direction: row;

    .audio-header {
      width: 70%;
      display: flex;

      .avatar {
        margin-right: 15px;

        img {
          width: 50px;
          height: 50px;
          border: solid 1px #979797;
        }
      }

      .titles {
        h3 {
          margin: 0;
          font-size: 16px;
          font-weight: 700;
        }
        h5 {
          margin-top: 5px !important;
          margin-bottom: 0;
          font-size: 12px !important;
          font-weight: 300;
           text-align: left;
        }
        .speaker {
          font-size: 12px;
          font-weight: bold;
          margin-bottom: 15px;
        }
      }
    }
    .buttons {
      display: flex;
      width: 30%;
      align-content: flex-end;
      justify-content: flex-end;

      button {
        padding: 0px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .controls {
    .track {
      display: flex;
      align-items: flex-start;

      & > div {
        flex-grow: 1;
        background: $background-grey;
        margin: 0 10px;
      }

      .progress {
        background: $blue;
        height: 3px;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .transcript-container {
    min-height: 90px;

    @media (max-width: 600px) {
      margin-bottom: 10px;
      min-height: 80px;
    }
    @media (min-width: 768px) {
      min-height: 75px;
    }
    @media (min-width: 1024px) {
      min-height: 65px;
    }
    @media (min-width: 1366px) {
      min-height: 55px;
    }
    p {
      margin-top: 0;
      font-size: 14px;
      line-height: 21px;
      transition: ease-in-out all .3s;
    }
  }
}
.resource{
  .track{
    width: 230px;
  }
}

@media screen and (max-width: 1550px) {
  .handbook {
    .content {
      .scroller {
        .section {
          .main {
            & > .half {
              display: block;

              &.side-by-side {
                display: grid;
              }

              table {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
