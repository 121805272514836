.profile {
  width: 100%;
  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    background: white;
    padding: 27px 40px 27px 20px;

    .role {
      flex-grow: 1;

      h2 {
        font-size: 20px;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0 0 8px;
      }

      h4 {
        font-size: 14px;
        font-weight: 300;
        text-transform: capitalize;
        margin: 0px;
      }
    }

    & > button {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      font-weight: 400;
      align-items: center;
      padding: 0px;

      img { margin-bottom: 7px;  }
    }
  }

  .change-role {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 12;

    .window {
      background: rgba(0,0,0,0.3);
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .choice-container {
      & > button {
        padding: 0px;
        height: 14px;
      }
    }
  }

  .tabs { display: none; }

  & > .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
    flex-grow: 1;

    & > div {
      padding: 0 20px;

      .hero {
        display: flex;
        margin-bottom: 20px;

        & > div:not(.filters) {
          flex-grow: 1;

          h3 {
            font-size: 20px;
            font-weight: 700px;
            margin: 0 0 6px;
          }

          h4 {
            font-size: 14px;
            font-weight: 300;
            margin: 0px;
          }
        }

        .filters {
          position: relative;

          button {
            padding: 0 13px;
            background: $blue;
            font-weight: 700;
            display: flex;
            color: white;
            align-items: center;
            align-self: flex-start;
            border-radius: 12px;
            line-height: 24px;
            font-size: 12px;
            width: 100%;

            img {
              margin-left: 9px;
            }
          }

          ul {
            background: white;
            margin: 0px;
            padding: 14px 0;
            list-style: none;
            position: absolute;
            top: 38px;
            right: 0px;
            z-index: 2;
            box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
            border-radius: 2px;

            li {
              padding: 0px;
              margin: 0px;
              box-shadow: none;
              border-radius: 0;
              margin-bottom: 16px;
              &:last-of-type {
                margin-bottom: 0px;
              }

              button {
                background: transparent;
                padding: 0 16px;
                font-size: 14px;
                font-weight: 300;
                color: $text;
                white-space: nowrap;

                &.active {
                  font-weight: 700;
                }
              }
            }
          }
        }
      }
    }

    & > .progress, .reading, .suggestions {
      border-right: 1px solid $light-grey;
      padding-bottom: 20px;
      margin-bottom: 20px;

      ul {
        margin: 0px;
        padding: 0px;
        list-style: none;

        li {
          background: white;
          box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
          border-radius: 4px;
          display: flex;
          position: relative;
          overflow: hidden;
          margin-bottom: 10px;
          cursor: pointer;
          outline: none;

          &:last-of-type { margin-bottom: 0px; }

          span:first-of-type {
            flex-grow: 1;
            font-size: 20px;
            font-weight: 700;
          }

          span:last-of-type {
            color: #C8C8C8;
            font-size: 12px;
            font-weight: 300;
          }

          .progress {
            position: absolute;
            bottom: 0px;
            left: 0px;
            height: 3px;
          }
        }
      }
    }

    & > .progress {
      ul {
        li {
          padding: 17px 12px;
        }
      }
    }

    .reading, .suggestions {
      ul {
        li {
          flex-direction: column;
          padding: 20px 20px 34px;

          span:first-of-type {
            font-size: 20px;
            margin-bottom: 10px;
          }

          span:last-of-type {
            font-size: 14px;
            color: $text;
          }

          .progress {
            height: 10px;
          }
        }
      }
    }

    .reading {
      ul {
        li {
          .progress-container {
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 10px;
            background: $background-grey;
            border-radius: 0 0 4px 4px;
            box-shadow: 0 0 0 1px #C8C8C8 inset;

            .progress {
              position: static;
            }
          }
        }
      }
    }

    .suggestions {
      ul {
        li {
          padding: 20px;
        }
      }
    }
  }

  .Toastify__toast-container--top-center {
    top: calc((100vh / 2) - 2.3rem);
  }
}
