@media screen and (max-width: 850px) {
  .onboard {
    & > div {
      .carousel-container {
        button:not(.goto) { display: none !important; }
      }
    }
  }

  .choice-container {
    width: calc(100% - 160px);

    footer {
      nav {
        display: flex;
        flex-grow: 1;

        button:first-of-type {
          margin-right: 35px;
        }

        button:last-of-type {
          flex-grow: 1;
          padding: 15px 0;
          margin-right: 0px;
        }
      }
    }
  }

  .page-wrapper {
    & > header {
      nav {
        button {
          display: block;
          padding: 0px;
          border-radius: 0;
          background: transparent;
          margin-right: 10px;
          color: $blue;
          text-decoration: underline;
          font-size: 0px;

          span { font-size: 12px; }

          svg {
            rect { fill: $blue; }
          }
        }

        & > div { display: none; }

        & > div.show {
          display: flex;
          position: fixed;
          top: 80px;
          left: 0px;
          width: calc(100% - 80px);
          height: calc(100% - 80px);
          background: white;
          z-index: 11;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 0 40px;

          a {
            display: flex;
            align-items: center;
            flex-direction: row;
            font-size: 36px;
            width: calc(100% - 80px);
            height: auto;
            justify-content: flex-start;
            margin-bottom: 50px;

            span {
              color: $blue;
            }

            &:last-of-type {
              margin-bottom: 0px;
            }

            div {
              height: 30px;
              width: 30px;
              margin-right: 24px;
              svg {
                height: 30px;
                path { fill: $blue; }
              }
            }

            &:hover {
              background: $blue;
              span { color: white;}
              svg {
                path {
                  fill: white;
                }
              }
            }
            &.active {
              width: 100%!important;
              span {
                font-weight: bold;
                color: white;
                width: 100%
              }

              svg {
                path {
                  fill: white;
                }
              }
            }
          }
        }
      }
    }
  }

  .handbook {
    display: block;

    aside {
      display: none;
      width: 100%;
      max-width: initial;
      flex-grow: 1;

      &.open {
        display: block;
      }

      .title {
        display: flex;
        justify-content: space-between;

        button {
          display: block;
          padding: 5px;
          background: transparent;
          color: white;
          text-decoration: underline;
          font-size: 12px;
        }
      }
    }

    .content {
      width: 100%;
      max-width: initial;

      header {
        .desktop-options { display: none !important; }
        .mobile-options { display: block !important; }
      }

      .scroller {
        .grid-three {
          margin-left: 0px;
        }

        .continue {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 20px;

          button {
            background: $blue;
            color: white;
          }
        }

        .section {
          flex-direction: column;

          .track {
            .title {
              position: static;
              margin-right: 0px;

              span {
                margin-top: 20px !important;
              }
            }
          }
        }
      }
    }

    .closed {
      display: none;
    }
  }

  .search {
    padding: 20px 0;

    h1, .input-container { margin: 0 20px; }
    .input-container { margin-bottom: 20px; }

    .main {
      flex-direction: column;

      aside {
        padding: 0px;
        border-right: none;

        div:first-of-type {
          display: flex;
          justify-content: flex-end;
          border-top: 1px solid $light-grey;
          border-bottom: 1px solid $light-grey;
          padding: 11px 20px;
          margin-bottom: 20px;

          button {
            background: $blue;
            border-radius: 18px;
            padding: 8px 5px 8px 12px;
            color: white;
            font-weight: 700;
            display: flex;
            align-items: center;

            &::after {
              content: '';
              width: 24px;
              height: 16px;
              display: block;
              background: url(../images/design/arrow-more-white.svg);
              background-position: 50%;
            }

            &.open::after {
              background: url(../images/design/arrow-less.svg);
              background-position: 50%;
            }
          }

          button.toggle {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            width: auto;
            margin-bottom: 0px;
          }

          & > div {
            display: none;
          }

          h4, h5 { display: none; }
        }

        .filters {
          margin: 0 20px;
          display: none;

          &.show { display: block; }
        }
      }
    }
  }

  .profile {
    .tabs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      background: white;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
      border-top: 1px solid $light-grey;
      border-bottom: 1px solid $light-grey;

      button {
        padding: 20px;
        color: $text;
        border-radius: 0px;

        &.active {
          border-bottom: 6px solid $blue;
        }
      }
    }



    & > .grid {
      background: $background-grey;
      margin: 0px;
      padding-top: 20px;
      display: block;

      & > div {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }

  .resource{
    .track {
      width: 100%;
    }
  }

  .four-oh-four {
    .container {
      margin: 0 20px;
    }
  }

  .about{
    .row {
      display: block !important;
    }
  }
}
