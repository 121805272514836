@media screen and (max-width: 450px) {
  .cookie-banner {
    padding: 20px;
    display: block;

    .buttons {
      display: block;
      text-align: center;
    }
  }

  .onboard {
    header {
      width: calc(100vw - 60px);
      margin-left: 30px;
      margin-right: 30px;
    }

    .carousel-container {
      .carousel {
        .carousel-image {
          max-width: 30vh;
          height: auto !important;
        }
      }
    }
  }

  .page-wrapper {
    header {
      .brand {
        span { display: none; }
      }

      nav {
        div.show {
          a {
            margin-bottom: 40px;

            div {
              margin-right: 16px;
              height: 20px;
              width: 20px;
              padding-top:5px;

              img {
                width: 20px;
              }
            }
            span { font-size: 24px; }
          }
        }
      }
    }

    .handbook {
      .content {
        header {
          padding: 16px 16px 26px;

          & > div.search-back {
            span {
              left: 16px;
            }
          }

          & > div > div {

            &:first-of-type {
              margin-right: 20px;
            }

            h1 {
              font-size: 16px;
              margin-bottom: 5px;
            }

            span {
              font-size: 12px;
            }
          }
        }

        .scroller {
          padding: 0 16px;
          height: calc(100vh - 177px);

          &.search-back {
            height: calc(100vh  - 239px);
          }
          .acronyms{
            grid-template-columns: 1fr !important;
          }
          .main-content {
            margin-left: 0;
          }

          & > .grid-three {
            grid-template-columns: 1fr;
          }

          .voices {
            .content {
              padding-top: 75px;

              h4 { margin: 0 20px; }
              h5 { margin: 0 20px; }
              .half {
                grid-template-columns: 1fr;
              }
            }
          }

          .template {
            table.grid {
              margin-bottom: 20px;
            }
          }

          .section {
            .main {
              .relative {
                margin-bottom: 30px;

                .description {
                  p { font-size: 12px; }
                }

                p.ref {
                  top: calc(100% + 2px);
                  right: 0px;
                  transform: none;
                  font-size: 12px;
                }
              }

              .flow {
                overflow-x: auto;
              }

              .grid-four {
                overflow-x: auto;

                .box { min-width: 200px; }
              }

              table.boxed-table {
                tbody {
                  tr {
                    display: flex;
                    flex-direction: column;
                    border-bottom: none;

                    td:first-of-type {
                      width: 100%;
                      font-weight: 700;
                    }
                  }
                }
              }
              .case-study {
                .content {
                  width: calc(100% - 40px);

                  .half {
                    grid-template-columns: 1fr;
                  }

                  .relative {
                    float: initial;
                    width: 100%;
                    padding: 0px;

                    .description {
                      bottom: 10px !important;
                      left: 10px !important;

                      p { font-size: 12px; }
                    }
                  }
                }
              }

              .odd-even {
                display: flex;
                overflow-x: auto;

                div { min-width: 150px; }
              }

              .start-third {
                grid-template-columns: 1fr;

                & > img {
                  display: none;
                }
                ul li {
                  word-break: break-word;
                }
              }
            }
          }
        }
      }
    }

    .audio-player {
      .row {
        display: block;
  
        .audio-header{
          width: 100%;
        }
        
        .buttons {
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    .search {
      width: 100%;

      h1 { display: none; }

      .input-container {
        input {
          padding: 17px;
        }
        button {
          padding: 0 20px;
          span { display: none; }
        }
      }

      .main {
        aside {
          min-width: initial;
          div:first-of-type { font-size: 12px; }

          & > div:first-of-type {
            display: flex;
            justify-content: flex-end;

            button.toggle {
              display: flex;
              justify-content: space-between;
              padding: 10px 20px;
              width: auto;
              margin-bottom: 0px;
            }

            & > div {
              display: none;
            }
          }
        }

        .results {
          display: flex;
          align-items: center;
          justify-content: center;

          .display {
            padding: 0 16px;
          }

          .empty {
            margin: 0px;
            width: 250px;
            p { font-size: 12px; }
          }
        }
      }
    }

    .profile {
      .change-role {
        z-index: 12;
      }

      .choice-container {
        padding: 16px;
        width: calc(100% - 64px);

        h2 {
          font-size: 16px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
        }

        .grid {
          grid-template-columns: 1fr;
          grid-row-gap: 16px;

          label {
            span {
              font-size: 14px;
            }
          }
        }

        footer {
          margin-top: 16px;
          flex-direction: column;

          span {
            font-size: 14px;
            margin-bottom: 16px;
          }

          nav {
            width: 100%;
            flex-direction: column;

            button:first-of-type {
              margin: 0 0 16px;
            }
          }
        }
      }
    }

    .four-oh-four {
      .container {
        margin: 0 20px;
      }
    }
  }

  .onboard {
    .change-role {
      z-index: 12;
    }

    .choice-container {
      padding: 16px;
      width: calc(100% - 64px);

      h2 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      p {
        font-size: 12px;
      }

      .grid {
        grid-template-columns: 1fr;
        grid-row-gap: 16px;

        label {
          span {
            font-size: 14px;
          }
        }
      }

      footer {
        margin-top: 16px;
        flex-direction: column;

        span {
          font-size: 14px;
          margin-bottom: 16px;
        }

        nav {
          width: 100%;
          flex-direction: column;

          button:first-of-type {
            margin: 0 0 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 320px) {
 .change-role {
   .choice-container {
     & > p {
       margin-bottom: 10px;
     }

     .grid {
       label {
          padding: 15px;
       }
     }
   }
 }
 .onboard {
  .carousel-container {
    .carousel {
      .carousel-image {
        max-width: 15vh;
        height: auto !important;
      }
    }
  }
 }
}