.search {
  padding: 26px 20px;
  flex-grow: 1;
  background-color: $background-grey;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 20px;
    margin: 0px;
  }

  & > .input-container {
    margin-top: 20px;
    margin-bottom: 40px;

    form {
      display: flex;
    }

    input {
      flex-grow: 1;
      border: 1px solid #DCDCDC;
      border-right: none;
      border-radius: 30px 0 0 30px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.02);
      color: $text;
      font-size: 14px;
      width: calc(100% - 74px);
      padding: 17px 35px;
      line-height: 24px;
      outline: none;

      &::placeholder {
        color: $light-grey;
      }
    }

    button {
      background: $blue;
      color: white;
      border-radius: 0 30px 30px 0;
      display: flex;
      align-items: center;
      padding: 0 27px;
      font-weight: 700;

      img { margin-right: 5px; }
    }
  }

  .main {
    display: flex;
    flex-grow: 1;

    aside {
      min-width: 400px;
      padding: 0 20px 0 0;
      border-right: 1px solid $light-grey;

      & > div:first-of-type {
        button.toggle {
          width: 100%;
          margin-bottom: 10px;
          display: none;
        }

        & > div {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          button.clear {
            background: transparent;
            font-weight: bold;
            margin: 0px;
            padding: 0px;
            text-decoration: underline;
          }
        }
      }

      .filters {
        display: none;

        &.show {
          display: block;
        }
      }

      label {
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
        background: white;
        border-radius: 4px;
        padding: 15px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        span {
          font-size: 12px;
          font-weight: 300;
          flex-grow: 1;
        }

        input { display: none; }

        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid $light-grey;
        }

        &.checked {
          background: $blue;
          span { color: white; }

          &::after {
            border: none;
            background: transparent url(../images/design/check.svg);
            background-position: 50%;
            height: 22px;
          }

        }
      }
    }

    .results {
      flex-grow: 1;

      .empty {
        width: 335px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #AFAFAF;
        margin: 370px auto;
      }

      .display {
        padding: 0 40px;

        h4 {
          font-weight: normal;
          margin: 0 0 25px;
        }

        div.result {
          background: white;
          box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
          border-radius: 4px;
          padding: 20px;
          margin-bottom: 20px;
          cursor: pointer;

          &:last-of-type {
            margin-bottom: 0px;
          }

          & > div {
            font-weight: 300;
            font-size: 14px;

            mark {
              background-color: $blue;
              color: white;
            }
          }

          .chapter-section {
            margin-top: 10px;
            border-left: 2px solid;
            padding-left: 10px;

            h3 { font-size: 16px; margin: 0; }
            h5 {
              font-size: 12px;
              margin: 5px 0;
            }

            &.un-blue { border-left-color: $un-blue; }

            &.un-green { border-left-color: $un-green; }

            &.un-purple { border-left-color: $un-purple; }

            &.un-orange { border-left-color: $un-orange; }

            &.un-navy { border-left-color: $un-navy; }

            &.un-red { border-left-color: $un-red; }
          }
        }
      }
    }
  }
}
