.four-oh-four {
  width: 100%;
  display: flex;
  flex-direction: column;

  .container {
    text-align: center;

    .info {
      margin-top: 160px;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 28px;
      margin: 0;
    }

    button {
      margin-top: 30px;
      background-color: $blue;
      color: white;
      font-weight: 400;
    }
  }
}