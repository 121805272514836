.about {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #F4F4F5;

  header {
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
    background: white;
    padding: 27px 40px 27px 20px;

    .title {
      flex-grow: 1;

      h2 {
        font-size: 20px;
        font-weight: 700;
        text-transform: capitalize;
        margin: 0 0 8px;
      }

      h4 {
        font-size: 14px;
        font-weight: 300;
        text-transform: capitalize;
        margin: 0px;
      }
    }
  }

  & > .row {
    display: flex;
    flex-direction: row;

    & > .row__item{
      background: white;
      margin: 15px;
      padding: 25px;
      box-sizing: border-box;
      border: 1px solid #DCDCDC;
      border-radius: 4px;
      align-items: stretch;

      h3 { font-size: 16px; margin: 10px 0 10px 0; }

      p {
        font-size: 14px;
        font-weight: 300;
      }
    }

    .info {
      flex: 70%;

      .signature {
        .signature-image {
          width: 204px;
          height: auto;
        }

        p {
          font-size: 12px;
          font-weight: 300;
          margin: 0;
        }
      }
      .content {
        margin-top: 40px;

        .copyright {
          p {
            margin: 0;
          }
        }
      }
    }

    .feedback {
      height: fit-content;
      min-height: 260px;
      flex-grow: 3;
      flex: 30%;

      .email {
        text-decoration: none !important;
        font-weight: bold;
        color: #3c3c3c;
      }
    }

  }

  .mobile-screenshot {
    max-width: 100%;
  }

  @media (min-width: 460px) {
    .mobile-screenshot {
      max-width: 320px;
    }
  }

  .desktop-screenshot {
    max-width: 100%;
  }

  ol li {
    font-size: 14px;
    font-weight: 300;
  }
}
