.onboard {
  background: #F4F4F5;
  min-height: 100vh;
  display: flex;
  align-items: initial;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 290px;
    
    @media (min-width: 415px) {
      margin-bottom: 240px;
    }

    header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 40px;

      img {
        height: 62px;
        width: 73px;
        margin-right: 24px;
      }

      span {
        color: $blue;
        font-weight: bold;
        line-height: 26px;
      }
    }

    .carousel-container {
      .carousel {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;


        @media (min-width: 768px) {
          flex-direction: row;
          margin-top: 40px;
        }

        .carousel-image {
          height: 420px;
          width: 360px;
          text-align: center;
          display: block;

          img { max-height: 100%; max-width: 100%; }
        }

        button {
          .next-icon, .prev-icon {
            circle {
              fill: $blue;
              stroke: $blue;
            }
          }

          &:disabled {
            .next-icon, .prev-icon {
              circle {
                fill: transparent;
                stroke: $blue;
              }
              polygon {
                fill: $blue;
              }
            }
          }
        }
      }

      .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 325px;
        @media (min-width: 768px) {
          align-items: start;
        }

        h3 {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 2px;
        }

        h2 {
          color: $dark-grey;
          display: inline-block;
          size: 24px;
          line-height: 28px;
          margin: 0 0 14px;
          font-weight: bold;
        }

        p {
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: $dark-grey;
          font-weight: 300;
          margin: 0;
          @media (min-width: 768px) {
            text-align: left;
          }
        }
      }

      button {
        margin-top: 45px;
        background-color: $blue;
        color: white;
        font-weight: bold;
      }
    }
  }
}

.choice-container {
  width: 860px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.02);
  background: white;
  border-radius: 2px;
  padding: 40px 50px;
  position: relative;

  h2 {
    margin: 0 0 20px;
    font-size: 24px;
  }

  p {
    font-size: 16px;
    margin: 0 0 40px;
    font-weight: 300;
  }

  .close {
    position: absolute;
    top: 21px;
    right: 21px;
    padding: 5px;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    label {
      display: flex;
      align-items: center;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);
      border: 1px solid $light-grey;
      border-radius: 4px;
      padding:  24px;
      transition: background 0.3s ease-in;

      span {
        font-size: 18px;
        font-weight: bold;
        flex-grow: 1;
      }

      input { display: none; }

      &::after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid $light-grey;
      }

      &.checked {
        background: $blue;
        span { color: white; }

        &::after {
          border: none;
          background: transparent url(../images/design/check.svg);
          background-position: 50%;
        }

      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    margin-top: 40px;

    span {
      flex-grow: 1;
      font-weight: 300;
    }

    nav {
      button.link {
        margin-right: 45px;
      }
    }
  }
}
