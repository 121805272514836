$light-grey: #DCDCDC;
$dark-grey: #3C3C3C;
$blue: #0056AD;
$text: #3C3C3C;
$background-grey: #F4F4F5;

$un-blue: #3D69B2;
$un-blue-25: rgba(61,105,178, 0.25);
$un-blue-50: rgba(61,105,178, 0.5);
$un-blue-75: rgba(61,105,178, 0.75);
$un-green: #52A665;
$un-green-25: rgba(82,166,101, 0.2);
$un-green-50: rgba(82,166,101, 0.3);
$un-green-75: rgba(82,166,101, 0.5);
$un-purple: #524689;
$un-purple-25: rgba(82,70,137, 0.25);
$un-purple-50: rgba(82,70,137, 0.5);
$un-purple-75: rgba(82,70,137, 0.75);
$un-orange: #F58644;
$un-orange-25: rgba(245,134,68, 0.25);
$un-orange-50: rgba(245,134,68, 0.5);
$un-orange-75: rgba(245,134,68, 0.75);
$un-navy: #3940A1;
$un-navy-25: rgba(57,64,161, 0.2);
$un-navy-50: rgba(57,64,161, 0.3);
$un-navy-75: rgba(57,64,161, 0.4);
$un-red: #DD444F;
$un-red-25: rgba(221,68,79, 0.25);
$un-red-50: rgba(221,68,79, 0.5);
$un-red-75: rgba(221,68,79, 0.75);

html, body {
  min-height: 100%;
  font-family: 'Poppins', sans-serif;
  color: $text;
  margin: 0px;
  padding: 0px;
}

#root {
  min-height: 100vh;
}

button {
  border-radius: 4px;
  padding: 15px 80px;
  background: white;
  color: $blue;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;

  &.primary {
    background: $blue;
    color: white;
    font-weight: bold;
  }

  &.link {
    background: transparent;
    text-decoration: underline;
    font-weight: bold;
    padding: 0px;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.un-blue {
  .progress { background: $un-blue; }
}

.un-green {
  .progress { background: $un-green; }
}

.un-purple {
  .progress { background: $un-purple; }
}

.un-orange {
  .progress { background: $un-orange; }
}

.un-navy {
  .progress { background: $un-navy; }
}

.un-red {
  .progress { background: $un-red; }
}

span, p, strong {
  &.un-blue { color: $un-blue; }
  &.un-green { color: $un-green; }
  &.un-purple { color: $un-purple; }
  &.un-orange { color: $un-orange; }
  &.un-navy { color: $un-navy; }
  &.un-red { color: $un-red; }
}

section {
  padding: 15px;

  & > p:first-of-type { margin-top: 0px; }
  & > p:last-of-type { margin-bottom: 0px; }
}

@import "onboarding";
@import "page";
@import "handbook";
@import "search";
@import "profile";
@import "four-oh-four";
@import "tablet";
@import "mobile";
@import "about";
