.page-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  & > header {
    height: 80px;
    display: flex;
    align-items: center;
    background: white;
    padding: 0 30px;
    z-index: 11;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.1);

    .brand {
      display: flex;
      align-items: center;
      flex-grow: 1;

      img {
        width: 61px;
        height: 52px;
        margin-right: 20px;
      }

      span {
        font-size: 16px;
        font-weight: bold;
        color: $blue;
      }
    }

    nav {
      display: flex;

      button {
        display: none;
      }

      & > div {
        display: flex;
      }

      a {
        padding: 0px;
        background: transparent;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        transition: background 0.3s ease-in;
        font-weight: 300;
        font-size: 10px;
        text-decoration: none;

        span { color: $blue; }

        svg {
          path {
            fill: $blue;
          }
        }

        & > div {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 5px;
        }

        &:hover, &.active {
          background: $blue;
          span { color: white; }
          svg {
            path {
              fill: white;
            }
          }
        }

        &.active {
          span {
            font-weight: bold;
            color: white;
          }

          svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }

  .page-container {
    flex-grow: 1;
    display: flex;
  }
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: $blue;
  padding: 40px;
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0 0 20px;
    color: white;
  }

  p {
    margin: 0 0 10px;
    color: white;
    font-weight: 200;
  }

  .buttons {
    align-self: center;
    display: flex;
    
    button:first-of-type {
      margin: 0 30px;
    }

    button {
      font-weight: bold;
    }

    .decline {
      background: inherit;
      color: #fff;
      text-decoration: underline;
      padding: 15px;
    }

    .accept {
      padding: 15px 120px;
    }
  }
}
